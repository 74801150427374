<template>
 <div class="sevenBox">
  <p class="info">
    地址: 上海市嘉定区安亭镇墨玉路28号嘉正国际大厦<span><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备15046583号-14</a></span>
  </p>
  <p class="info">版权所有: 上海老邦网络科技有限公司<span>Copyright 2017-2019</span><span>Laobour Group All Rights Reserved</span></p>
<!--  <div class="link">-->
<!--    <p>-->
<!--      友情链接: -->
<!--      <a :href="list.href" v-for="list in linkList" target="_parent">{{list.label}} </a>-->
<!--    </p>-->
<!--  </div>-->
</div>
</template>
<script>
export default {
  // data () {
  //   return {
  //     linkList:[
  //       {href:"", label:"劳务邦"},
  //       {href:"", label:"泰盛人力"},
  //       {href:"", label:"杰博人力"}
  //     ]
  //   }
  // },
  // methods: {
  //   getLinks () {
  //     this.$store.dispatch('getLinks').then(res => {
  //       this.linkArr = res
  //     })
  //   }
  // },
  // mounted () {
  //   this.getLinks()
  // }
}
</script>
<style lang=less>
#foot {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3.5rem;
  text-align: center;
  .flex;
  flex-direction: column;
  color: @global-white;
  p{
    margin: 0;
    margin-bottom: 1rem;
    span {
      margin-left: .5rem;
    }
  }
  p:last-child {
    margin-bottom: 0;
    a {
      margin-left: 1rem;
    }
  }
  a {
    color: @global-white;
  }
}
  @media screen and (max-width: 768px) {
    #foot {
      padding: 1rem;
      /*font-size: .5rem;*/
      p{
        /*margin-bottom: 0;*/
      }
    }
  }
</style>
