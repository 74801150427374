import Vue from "vue";
// 获取首页的轮播图
export function getSwipers() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/banners`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}

// 获取新闻列表
export function getNews({ commit }, params) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(params.url || `/api/web/news?page=` + params.page).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}

// 获取新闻列表
export function getHotsNews({ commit }, params) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/news/hots`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}


// 获取合作伙伴
export function getPartner({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/partners`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取产品案例数据
export function getProduceCase({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/examples`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 友情链接
export function getLinks() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/links`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取总裁事务页数据
export function getCeo() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/tags/ceo`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取财税管理页数据
export function getTax() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/tags/tax`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取人才管理页数据
export function getHr() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/tags/hr`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取产品方案数据
export function getSolutions() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/solutions`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取文库导航
export function getProduct() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/tags`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取文库列表
export function getArticles({ commit }, params) {
  const getUrl = `/api/web/articles?` + params;
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(getUrl).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 订购文章
export function subscribe({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/articles/subscribe`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 支付订阅文章
export function paySubscribe({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/articles/pay`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 获取人才管理，财税管理和总裁事务的详情
export function getAdminDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/solutions/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取人才管理，财税管理和总裁事务的详情
export function getWechatJssdk({ commit }) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/wechat/jssdk`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取新闻详情
export function getNewsDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/news/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取产品案例详情数据
export function getProduceDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/examples/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取文库详情
export function getArticleDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/articles/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 设置视频播放时间
export function setTime({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .patch(`/api/web/articles/play_at`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        }
      });
  });
}
// 可开通的vip列表
export function getVips({ commit }) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/vips`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 购买vip
export function payVip({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/vip/purchase`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        }
      });
  });
}
// 查看用户是否支付完成
export function payOff({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .get(`/api/web/order/status/${payload}`)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        }
      });
  });
}
// 提交留言
export function addCus({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/contacts`, payload).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}

// 用户注册
export function register({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/register`, payload).then(response => {
      if (response.status === 200) {
        commit("setLoginToken", response.data.data);
        resolve(response.data);
      }
    });
  });
}

// 用户登录
export function login({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/login`, payload).then(response => {
      if (response.status === 200) {
        if (response.data.code === 0) {
          commit("setLoginToken", response.data.data);
        }
        resolve(response.data);
      }
    });
  });
}
// 获取文章列表 （商家）
export function getStoreArticles({ commit }, url) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(url || `/api/web/store/articles`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取文章详情 （商家）
export function getStoreArticleDetail({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/articles/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取标签列表
export function getTag({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/tags`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 上传图片
export function uploadImg({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/store/upload_image`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 获取oss配置信息
export function getOss({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/alioss`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 文件/视频提交到oss（阿里云）
export function putOss({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(payload.url, payload.param).then(response => {
      if (response.status === 200) {
        resolve(response);
      }
    });
  });
}
// 发表文章
export function publishArticles({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/store/articles`, payload)
      .then(response => {
        if (response.status === 201) {
          resolve(response.data);
        }
      });
  });
}
// 修改文章
export function updateArticles({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .put(`/api/web/store/articles/${payload.id}`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 删除文章
export function deleteArticles({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .delete(`/api/web/store/articles/${payload}`)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 商家入驻
export function tenants({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/store/apply`, payload).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 获取用户个人信息
export function getInfo({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/info`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 修改个人信息
export function updateInfo({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.patch(`/api/web/user`, payload).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 修改个人密码
export function updatePwd({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .patch(`/api/web/user/modify_password`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 开店成功信息提示
export function getMark() {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.patch(`/api/web/store/mark_read`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取积分列表
export function getPoints({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/integrals`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取测评记录
export function getTests({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/test_records?` + payload).then(response => {
      if (response.status === 200) {
        resolve(response.data.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 获取试卷
export function getPapers({ commit }, param) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/papers?page=` + param.page).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 获取试卷详情
export function getPaperInfo({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/papers/${payload}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 开始考试
export function startPaper({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/papers/start/${payload}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 结束考试
export function finishPaper({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/papers/finish/${payload.id}`, payload).then(response => {
      if (response.status === 200) {
        resolve(response.data.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 获取考试结果
export function getTest({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/test/${payload}`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 获取考试结果
export function getExams({ commit }, param) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/exams?page=` + param.page).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    }).catch(res => {
      reject(res)
    })
  })
}
// 获取交易记录
export function getOrders({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/orders`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取阅读记录
export function getRecords({ commit }, payload) {
  const getUrl = `/api/web/store/read_records?` + payload;
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(getUrl).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取员工的阅读记录
export function getErecords({ commit }, payload) {
  const getUrl = `/api/web/store/users_read_records?` + payload;
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(getUrl).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}
// 获取部门管理信息
export function getDepartments({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/departments/all`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}

// 获取角色管理信息
export function getRolesList({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/roles/all`).then(response => {
      if (response.status === 200) {
        resolve(response.data.data);
      }
    });
  });
}

// 增加角色管理
export function addSaveRoless({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/store/roles`, payload).then(response => {
      if (response.status === 201) {
        resolve(response.data);
      }
    });
  });
}

// 删除部门信息
export function deleteRoless({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.delete(`/api/web/store/roles/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 修改部门信息
export function updateRoless({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .put(`/api/web/store/roles/${payload.id}`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}

// 增加部门
export function addDepartments({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/store/departments`, payload)
      .then(response => {
        if (response.status === 201) {
          resolve(response.data);
        }
      });
  });
}
// 删除部门信息
export function deleteDepartments({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .delete(`/api/web/store/departments/${id}`)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 修改部门信息
export function updateDepartments({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .put(`/api/web/store/departments/${payload.id}`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}

// 获取员工列表信息
export function getEmployees({ commit }, url, params = {},) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .get(url || `/api/web/store/users`, { params: params })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.data);
        }
      });
  });
}
// 增加员工信息
export function addEmployees({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/store/users`, payload).then(response => {
      if (response.status === 201) {
        resolve(response.data);
      } else if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 导入员工
export function importUser({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(`/api/web/store/users_import`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 导出员工
export function exportUser({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .get(`/api/web/store/users_export`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}
// 删除部门信息
export function deleteEmployees({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.delete(`/api/web/store/users/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 修改部门信息
export function updateEmployees({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .put(`/api/web/store/users/${payload.user_id}`, payload)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        }
      });
  });
}

// 微信登录
export function getWxLogin({ commit }, params) {
  return new Promise((resolve, reject) => {
    console.log(params);
    Vue.prototype.$http.post(`/api/web/wechat`, params).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}
// 验证文章修改删除发布
export function storeCanPub({ commit }, id) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/can_pub/${id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}

// 上下架
export function pushIsShow({ commit }, params) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.post(`/api/web/store/${params.url}/${params.id}`).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      }
    });
  });
}

// 导出测评
export function exportTest({ commit }, payload) {
  return new Promise((resolve, reject) => {
    console.log(payload, 'payload');
    Vue.prototype.$http.get(`/api/web/store/test_export`, { params: payload, responseType: 'blob', }).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}


// 导出观看记录
export function exportEviewList({ commit }, payload) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$http.get(`/api/web/store/read_export`, { params: payload, responseType: 'blob' },).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      }
    })
  })
}
