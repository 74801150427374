<template>
  <el-menu :default-active="activeIndex" :router="true" class="el-menu-demo" :mode="mode" active-text-color="#135cb1" :key="activeIndex" :index="activeIndex">
      <template v-for="(list, index) in navData">
        <el-submenu v-if="list.childern && list.childern.length > 0" :index="list.id" :key="'m' + index" >
          <template slot="title">{{list.navName}}</template>
          <template v-if="mode == 'horizontal' ">
            <el-menu-item :index="list.id"  v-for="(ele) in list.childern" :route="{ name: ele.navLink }">{{ele.navName}}</el-menu-item>
          </template>
          <template v-else>
            <el-menu-item-group>
              <el-menu-item :index="list.id"  v-for="(ele) in list.childern" :route="{ name: ele.navLink }">{{ele.navName}}</el-menu-item>
            </el-menu-item-group>
          </template>
        </el-submenu>
        <el-menu-item :index="list.id" :key="'m' + index" :route="{ name: list.navLink }" v-else>
          {{list.navName}}
        </el-menu-item>
      </template>
    <el-menu-item index="6" :route="{ name: 'UserHome' }" v-if="getToken && mode == 'horizontal' ">个人中心</el-menu-item>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
  export default {
  name: 'menuPage',
  props: {
    mode: {
      type: String,
      default:'horizontal'
    },
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath"]),
  },
  data() {
    return {
      activeIndex: "0",
      navData: [
        { id: "0", navName: "首页", navLink: "Index", isShow: true },
        {
          id: "1", navName: "知识库", navLink: "Articles", isShow: true},
        // { id: "2", navName: "产品中心", navLink: "Products", isShow: true },
        { id: "2", navName: "解决方案", isShow: true, childern: [
            { id: "21", navName: "人才赋能与评价", navLink: "Solutions", isShow: true },
            { id: "22", navName: "新进员工培训", navLink: "FacingProblems", isShow: true },
          ]
        },
        { id: "3", navName: "干货资讯", navLink: "News", isShow: true },
        { id: "4", navName: "关于我们", navLink: "Contactus", isShow: true },
        // { id: "5", navName:'面临问题', navLink:'FacingProblems', isShow:true},
      ],
    }
  },
  watch: {
    $route(to, from) { 
      this.navData.map((item, key) => {
        let name_ = to.meta.name || to.name;
        if (name_ === item.navLink) {
          this.activeIndex = item.id;
        }
      });
    }
  },
  mounted() {
    
  },
  }
</script>

<style lang="scss">
.el-menu-demo>.el-menu-item.is-active{
	//border-bottom:4px solid #409EFF;
  background:#f5f5f5;
  border-bottom:0;
}
</style>
