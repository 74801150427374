<template>
  <!--头部-->
  <div :tt="clientWidth" :key="isMobile">
    <div class="top">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="right">
        <Menu ref="pcMenuBox" v-if="clientWidth > 900" />
        <!--移动端-->
        <i class="el-icon-menu menuBtn" @click="mobileShowMenu = true" v-else></i>
      </div>
      <div class="sign">
        <el-button type="primary" size="mini" v-if="!getToken" @click="skip()">登录/注册</el-button>
        <el-dropdown :hide-on-click="false" v-if="getToken">
          <span class="el-dropdown-link">
            <img src="../assets/images/ceo-pic.png" alt style="width:30px;position: relative;top:-2px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="outLogin()">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!--移动端左侧菜单栏-->
    <el-drawer
      title=""
      :visible.sync="mobileShowMenu"
      direction="ltr"
      size="50%"
      :before-close="handleClose">
      <Menu ref="mobileMenuBox" mode="vertical"/>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "./Menu.vue";
export default {
  components:{Menu},
  data() {
    return {
      clientWidth: 10,
      mobileShowMenu:false
    };
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath"]),
    isMobile() {
      return !(this.clientWidth > 900)
      // if () {
      //   let isBool = localStorage.getItem('isMobile');
      //   console.log(isBool, typeof isBool);
        
      //   if (typeof isBool == 'string') {
      //     return isBool == 'true'
      //   } else {
      //     return isBool
      //   }
      // }
    }
  },
  methods: {
    // 退出登录
    outLogin() {
      this.$store.commit("logout");
      this.$router.push({ name: "Login" });
    },
    showTabs() {
      if (this.$refs.routerBox.style.display === "block") {
        this.$refs.routerBox.style.display = "none";
      } else {
        this.$refs.routerBox.style.display = "block";
      }
    },
    // 跳转登录注册页面
    skip() {
      if (!this.isWeixinBrowser()) {
        this.$store.commit("setLastPath", this.$route.fullPath);
      }
      this.$router.push({ name: "Login" });
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
    handleClose() {
      this.mobileShowMenu = false
    }
  },
  mounted() {
    this.clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth || 10;
    window.addEventListener("resize", () => {
      this.clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth || 10;
      console.log(this.clientWidth, 'this.clientWidth');
    });
    const _thisVue = this;
    const storageHandler = function () {
      _thisVue.$store.commit("syncToken", {
        LbjtAccessToken: localStorage.LbjtAccessToken || null,
        LbjtTokenType: localStorage.LbjtTokenType || null,
        LbjtExpiresTime: localStorage.LbjtExpiresTime || null,
      });
    };
    window.addEventListener("storage", storageHandler, false);
  },
  watch: {
    getToken(val, oldVal) {},
    $route(to, from) {
      this.$store.commit("syncToken", {
        LbjtAccessToken: localStorage.LbjtAccessToken || null,
        LbjtTokenType: localStorage.LbjtTokenType || null,
        LbjtExpiresTime: localStorage.LbjtExpiresTime || null,
      });
      this.mobileShowMenu = false;
    },
  },
};
</script>

<style scoped lang="less">
.head {
  width: @global-min-width;
  margin: 0 auto;
  padding-top: 20px;
  position: relative;
  // display: flex;
  // justify-content: center;
  .head-logo {
    margin-right: 5%;
    img {
      width: 110px;
    }
  }
  .head-list {
    display: flex;
    text-align: center;
    align-items: center;
    border: none;
    margin-left: 100px;
    li {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      /*margin-left: 30px;*/
      a {
        display: inline-block;
        line-height: 50px;
        width: 100%;
      }
    }
  }
  .el-button {
    position: absolute;
    padding: 5px 10px;
    margin-left: 10px;
    right: 50px;
  }
  .head-btn {
    display: none;
  }
  .el-dropdown {
    margin-left: 0.5rem;
    img {
      width: 30px;
    }
  }
}

// 适应移动端
@media screen and (min-width: 900px) and (max-width: 1100px) {
  .head {
    width: auto;
    .head-btn {
      display: none;
    }
    .el-button {
      position: static;
    }
  }
}
@media screen and (max-width: 900px) {
  .head {
    width: auto;
    padding: 20px 30px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .header-box {
      width: 100%;
      position: absolute;
      top: 80px;
      left: 0;
      z-index: 999;
      flex-direction: column;
      align-content: center;
      background: @global-white;
      padding: 0;
      margin-left: 0;
      .head-list {
        width: 100%;
        /*position: absolute;*/
        /*top: 80px;*/
        /*left: 0;*/
        /*z-index: 999;*/
        flex-direction: column;
        align-content: center;
        background: @global-white;
        padding: 0;
        margin-left: 0;
        li {
          width: 100%;
          border-bottom: 1px solid #f2f2f2 !important;
          text-align: center;
        }
        .is-active {
          border-bottom: 2px solid #ffd04b !important;
        }
      }
    }
    .el-button {
      position: static;
    }
    .head-btn {
      display: block;
      height: 40px;
      padding: 0 20px;
    }
  }
}
</style>
